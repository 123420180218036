import React, { useState, useEffect } from "react";

import "./App.css";

import Web3 from "web3";
import BigNumber from "bignumber.js";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer, toast, Bounce } from "react-toastify";
import { contractAddress, contractABI } from "./contracts/contractInfo";
import { loadWeb3, checkAndSwitchNetwork } from "./components/web3Setup";


import TokenomicsSection from "./components/tokenomics";
import Roadmap from "./components/Roadmap";

import logo from "./assets/logo.png";
import hamburger from "./assets/hamburger.svg";
import klaus from "./assets/klaus.png";
import X from "./assets/X.png";
import TG from "./assets/TG.png";
import poweredby from "./assets/poweredby.png";


function App() {
  const tokenPriceInBTC = 0.00000015;
  const [isConnected, setIsConnected] = useState(null);
  const [isMinting, setIsMinting] = useState(null);
  const [isLoading, setIsLoading] = useState(null);
  const [contractInstance, setContractInstance] = useState(null);
  const [maxSupply, setMaxSupply] = useState(0);
  const [totalSupply, setTotalSupply] = useState(0);
  const [percentage, setPercentage] = useState(0);

  const [USDPrice, setUSDPrice] = useState(0);
  const [tokenAmount, setTokenAmount] = useState(0);
  const [isFullScreen, setIsFullScreen] = useState(false);

  const [BTCPrice, setBTCPrice] = useState(null);
  const [valueInUSD, setValueInUSD] = useState(null);

  const onConnect = async () => {

    setIsLoading(true);
    const web3Loaded = await loadWeb3();

    if (web3Loaded) {
      let isCorrectNetwrok = await checkAndSwitchNetwork();
      if (isCorrectNetwrok) {
        const web3 = new Web3(window.ethereum);

        setIsConnected(true);
        setIsLoading(false);

        const tempContract = new web3.eth.Contract(contractABI, contractAddress);
        setContractInstance(tempContract);
        notify("Wallet is connected");

        const maxSupply = await tempContract.methods.maxSupply().call();
        const totalSupply = await tempContract.methods.totalSupply().call();
        setMaxSupply(weiToEthersBN(maxSupply));
        setTotalSupply(weiToEthersBN(totalSupply));

      } else {
        setIsLoading(false);
        notify("Please switch your wallet to SatoshiVM Alpha Mainnet.", "error");
      }
    } else {
      setIsLoading(false);
      notify("Error while connecting wallet.", "error");
    }
  };

  const onDisconnect = () => {
    setIsConnected(null);
    setContractInstance(null);

    setUSDPrice(0);
    setTokenAmount(0);
    notify("Wallet Disconnect", "error");
  };

  const onMintToken = async () => {
    setIsMinting(true);
    let isCorrectNetwrok = await checkAndSwitchNetwork();

    if (!isCorrectNetwrok) {
      setIsMinting(false);
      notify("Please switch your wallet to SatoshiVM Alpha Mainnet.", "error");
      return
    }

    const accounts = await window.ethereum.request({ method: "eth_accounts" });
    const account = accounts[0];

    if (contractInstance) {
      const valueInWei = calculatePrice(tokenAmount);

      try {
        contractInstance.methods
          .mint(tokenAmount)
          .send({
            from: account,
            value: valueInWei,
            gas: 60000,
            maxFeePerGas: 75000000,
            maxPriorityFeePerGas: 0,
          })
          .on("transactionHash", (transactionHash) => {
            notify("Transaction submitted to network!", "success");
            console.log(`Transaction hash: ${transactionHash}`);
          })
          .on("receipt", (receipt) => {
            setIsMinting(false);
            notify("Token minting Completed!", "success");
            console.log(`Minting ${tokenAmount} tokens...`, receipt);
          })
          .on("error", (error) => {
            setIsMinting(false);
            notify("Error in minting tokens", "error");
            console.error("Error minting tokens:", error);
          })
          .catch((error) => {
            setIsMinting(false);
            if (error.code === 4001) {
              notify("User rejected the transaction", "error");
            } else {
              notify("Error in minting tokens", "error");
            }
          });
      } catch (error) {
        setIsMinting(false);
      }
    } else {
      setIsMinting(false);
    }
  };

  const calculatePrice = (tokenAmount) => {
    const priceInWei = ethersToWeiBN(tokenPriceInBTC);

    const totalPriceInWei = priceInWei * tokenAmount;

    return totalPriceInWei.toString();
  };

  function weiToEthersBN(weiValue) {
    const weiValueStr = weiValue.toString();

    return new BigNumber(weiValueStr)
      .dividedBy(new BigNumber("10").pow(18))
      .toString();
  }

  function ethersToWeiBN(etherValue) {
    const etherValueStr = etherValue.toString();

    return new BigNumber(etherValueStr)
      .multipliedBy(new BigNumber("10").pow(18))
      .toString();
  }

  const notify = (message, type = "success") => {
    toast[type](message, {
      position: "bottom-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "colored",
      transition: Bounce,
    });
  };
  const formatNumber = (num) => {
    return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  };

  useEffect(() => {
    const fetchBTCPrice = async () => {
      try {
        const response = await fetch(
          "https://api.coingecko.com/api/v3/simple/price?ids=bitcoin&vs_currencies=usd"
        );
        const data = await response.json();
        setBTCPrice(data.bitcoin.usd);
      } catch (error) {
        console.error("Failed to fetch BTC price:", error);
        setBTCPrice(0);
      }
    };

    fetchBTCPrice();
  }, []);

  useEffect(() => {
    const rpcURL = 'https://alpha-rpc-node-http.svmscan.io';
    const web3 = new Web3(new Web3.providers.HttpProvider(rpcURL));
    const contract = new web3.eth.Contract(contractABI, contractAddress);

    const fetchData = async () => {
      try {
        const maxSupply = await contract.methods.maxSupply().call();
        const totalSupply = await contract.methods.totalSupply().call();

        setMaxSupply(weiToEthersBN(maxSupply));
        setTotalSupply(weiToEthersBN(totalSupply));
        setPercentage((weiToEthersBN(totalSupply) / weiToEthersBN(maxSupply)) * 100).toFixed(2);

      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    if (tokenAmount > 0)
      setValueInUSD((tokenAmount * tokenPriceInBTC * BTCPrice).toFixed(2));
    else setValueInUSD(0);
  }, [tokenAmount]);

  return (
    <div>
      {isFullScreen && (
        <div class="fullscreen-menu">
          <div class="flex justify-end">
            <div onClick={() => setIsFullScreen(false)}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="48"
                height="48"
                fill="none"
                viewBox="0 0 48 48"
              >
                <path
                  stroke="#fff"
                  stroke-linecap="round"
                  stroke-width="2"
                  d="M10 10l14 14 14-14M10 38l14-14 14 14"
                ></path>
              </svg>
            </div>
          </div>
          <ul class="text-center flex flex-col gap-4">
            <li onClick={() => setIsFullScreen(false)} class="text-white ">
              {" "}
              <a href="#hero">Home</a>{" "}
            </li>
            <li onClick={() => setIsFullScreen(false)} class="text-white ">
              {" "}
              <a href="#mint">Mint</a>{" "}
            </li>
            <li onClick={() => setIsFullScreen(false)} class="text-white">
              {" "}
              <a href="#tokenomics">Tokenomics</a>{" "}
            </li>
            <li onClick={() => setIsFullScreen(false)} class="text-white">
              {" "}
              <a href="#roadmap">Roadmap</a>{" "}
            </li>
          </ul>
          <div></div>
        </div>
      )}
      <section className="bg-red py-8 relative font-primary">
        <div className="w-[100%] md:[90%] xl:w-[85%] mx-auto flex justify-between items-center">
          <div className="steak-logo-wrapper">
            <img
              className="steak-logo w-[72px] h-[72px] md:w-[150px] md:h-[150px]"
              src={logo}
              alt=""
            />
            <p>A meme coin  with real <br /> utility!</p>
          </div>
          <div className="md:flex gap-6 hidden lg:ml-24 2xl:ml-0">
            <a href="#hero" className="text-white font-bold cursor-pointer">
              HOME
            </a>
            <a href="#mint" className="text-white font-bold cursor-pointer">
              MINT
            </a>
            <a
              href="#tokenomics"
              className="text-white font-bold cursor-pointer"
            >
              TOKENOMICS
            </a>
            <a
              href="#roadmap"
              className="text-white font-bold cursor-pointer"
            >
              ROADMAP
            </a>
          </div>

          <a
            onClick={isConnected ? onDisconnect : onConnect}
            className="btn btn-0"
          >
            {isLoading
              ? "Connecting..."
              : isConnected
                ? "Disconnect"
                : "Connect Wallet"}
          </a>
          <img
            onClick={() => setIsFullScreen(true)}
            src={hamburger}
            className="block md:hidden cursor-pointer"
            alt=""
          />
        </div>
      </section>
      <section
        className="py-14 md:py-28 overflow-x-hidden bg-white font-primary"
        id="hero"
      >
        <div className="w-[95%] md:[90%] xl:w-[85%] mx-auto">
          <div className="flex flex-col items-center md:flex-row md:items-start gap-10">
            <div className="flex w-[100%] md:[90%] xl:w-[85%] flex-col flex-grow gap-8 text-center pt-10">
              <div className="flex flex-col w-[100%] xl:w-[100%] gap-4">
                <span className="header text-[5vw] sm:text-[4vw] md:text-[3.9vw] lg:text-[3.9vw] xl:text-[3.9vw] text-justify text-red bg-yellow uppercase font-primary">
                  YOU WILL EAT ZE BUGS <br />
                  UNLESS YOU BUY  <span className="font-bold"> $STEAK! </span>
                </span>
                <p className="text-[#D65752] text-[4vw] sm:text-[3vw] lg:text-[2.5vw] xl:text-[2vw] font-medium text-justify">
                  Don't let the WEF control what you do!
                  <br />
                  Klaus Schwab can eat his bugs, we will buy $STEAK!
                </p>
              </div>
            </div>
            <div className="w-[40%] md:[40%] xl:w-[40%] sm:block">
              <img className="hero-image" src={klaus} alt="" />
            </div>
          </div>
          <div className="flex flex-row pt-10 md:pt-20 justify-center items-center gap-[10vw]">
            <a
              href="https://twitter.com/SteakMemeCoin"
              target="_blank"
              rel="noreferrer"
            >
              <img
                className="w-12 hover:scale-105 transition-all duration-200 ease-out"
                src={X}
                alt="X Logo"
              />
            </a>
            <a href="https://t.me/SteakMemeCoin" target="_blank" rel="noreferrer">
              <img
                className="w-12 hover:scale-105 transition-all duration-200 ease-out"
                src={TG}
                alt="Telegram Logo"
              />
            </a>
          </div>
        </div>
      </section>

      {/* Minting Section Start */}
      <section className="py-24 bg-blue-100 font-primary" id="mint">
        <div className="w-full max-w-xl mx-auto text-center">
          <h1 className="text-5xl font-bold mb-6 text-red-100">Mint Your Tokens</h1>
          {/* <p className="text-lg text-gray-800 mb-3">Max Supply: {maxSupply} STEAK</p>
          <p className="text-lg text-gray-800 mb-6">Total Supply: {totalSupply} STEAK</p> */}
          <div className="flex items-center justify-between bg-gray-100 p-4 rounded-lg shadow-md mb-6">
            <div className="flex flex-col">
              <span className="text-sm font-semibold text-gray-600">Minted</span>
              <span className="text-lg font-bold">{formatNumber(totalSupply)}</span>
            </div>
            <div className="relative flex-grow mx-4">
              <div className="h-5 w-2/3 bg-gray-300 rounded-full overflow-hidden">
                <div
                  className="h-full bg-blue-500 rounded-full"
                  style={{ width: `${percentage}%` }}
                ></div>
              </div>
              <span className="absolute inset-y-0 left-1/2 transform -translate-x-1/2 flex items-center text-sm text-gray-700">
                {percentage.toFixed(2)}%
              </span>
            </div>
            <div className="flex flex-col">
              <span className="text-sm font-semibold text-gray-600">Max Supply</span>
              <span className="text-lg font-bold">{formatNumber(maxSupply)}</span>
            </div>
          </div>
          <p className="text-lg text-left text-blue-700 mb-3 ml-1">Enter the number of tokens you want to mint:</p>
          <div className="flex flex-col items-center mb-6">
            <input
              type="number"
              id="tokenAmount"
              placeholder="Token amount"
              className="bg-gray-50 border border-gray-300 text-gray-900 text-xl rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              onChange={(e) => {
                setTokenAmount(e.target.value);
              }}
            />
          </div>
          <div id="priceDisplay" className="text-lg font-medium mb-4 text-blue-800">Price: ${valueInUSD}</div>
          {isConnected ? (
            <button
              onClick={() => onMintToken()}
              className="mt-6 px-6 py-2 bg-red text-white font-semibold rounded-md hover:bg-red-700 transition duration-200"
              disabled={isMinting}
            >
              {isMinting ? "Minting..." : "Mint Tokens"}
            </button>
          ) : (
            <button
              onClick={() => onConnect()}
              className="mt-6 px-6 py-2 bg-red text-white font-semibold rounded-md hover:bg-red-700 transition duration-200"
              disabled={isLoading}
            >
              {isLoading ? "Connecting..." : "Connect Wallet"}
            </button>
          )}
          <p className="text-xl text-center text-black mt-8 mb-3 ml-1">Initial FDV: $200,000</p>
        </div>
      </section>
      {/* Minting Section End */}

      {/* Tokenomics Section Start*/}
      <TokenomicsSection />
      {/* Tokenomics Section End */}

      {/* Roadmap Section Start*/}
      <Roadmap />
      {/* Roadmap Section End */}

      <footer className="p-2" style={{ background: '#101112' }}>
        <div className="w-[100%] md:[90%] xl:w-[75%] mx-auto flex justify-between items-center">
          {/* Left side: Logo and Name */}
          <div className="flex items-center">
            <img src={poweredby} alt="Your Logo" className="w-92 mr-2" />
          </div>

          {/* Right side: Social Icons */}
          <div className="flex items-center gap-16">
            <a href="https://twitter.com/SteakMemeCoin" target="_blank" rel="noreferrer">
              <img
                src={X}
                alt="Twitter Logo"
                className="w-12 hover:scale-105 transition-all duration-200 ease-out"
              />
            </a>
            <a href="https://t.me/SteakMemeCoin" target="_blank" rel="noreferrer">
              <img
                src={TG}
                alt="Telegram Logo"
                className="w-12 hover:scale-105 transition-all duration-200 ease-out"
              />
            </a>
          </div>
        </div>
      </footer>


      <ToastContainer />
    </div>
  );
}

export default App;
