
import React, { useState, useEffect } from "react";
import joketext from "../assets/joketext.png";

const RoadmapComponent = () => {
    return (
        <section class="roadmap bg-blue-100 ps-timeline-sec flex justify-center" id="roadmap">
            <div class="container">
                <h3 className="text-4xl mt-16 font-bold mb-4">Roadmap</h3>
                <ol class="ps-timeline">
                    <li>
                        <div class="ps-bot">
                            <p>1st Quarter Post Minting:</p>
                            <div className="flex">
                                <small className="dot mr-2">•</small>
                                <small>Enable $STEAK staking</small>
                            </div>
                            <div className="flex">
                                <small className="dot mr-2">•</small>
                                <small>Launch our first DApp on SatoshiVM, designed to generate profits for $STEAK stakers.</small>
                            </div>
                            <div className="flex">
                                <small className="dot mr-2">•</small>
                                <small>List on CEX</small>
                            </div>
                            <div className="flex">
                                <small className="dot mr-2">•</small>
                                <small>Enable bridging $STEAK to the Ethereum network</small>
                            </div>
                        </div>
                        <span class="ps-sp-top">1<sup>st</sup> </span>
                    </li>
                    <li>
                        <div class="ps-top">
                            <p>2nd Quarter Post Minting:</p>
                            <div className="flex">
                                <small className="dot mr-2">•</small>
                                <small> Enable bridging $STEAK to the Solana network</small>
                            </div>
                            <div className="flex">
                                <small className="dot mr-2">•</small>
                                <small> Launch an NFT collection associated with our token</small>
                            </div>
                            <div className="flex">
                                <small className="dot mr-2">•</small>
                                <small> Execute a versatile marketing campaign</small>
                            </div>
                            <div className="flex">
                                <small className="dot mr-2">•</small>
                                <small> Launch more DApps on SatoshiVM</small>
                            </div>
                        </div>
                        <span class="ps-sp-bot">2<sup>nd</sup></span>
                    </li>
                    <li>
                        <div class="ps-bot">
                            <p>
                                3rd Quarter Post Minting:
                            </p>
                            <div className="flex">
                                <small className="dot mr-2">•</small>
                                <small>
                                    Construct a $STEAK citadel on Mars, renowned for serving only the finest organic, free-range, grass-fed steak. Available only for $STEAK NFT holders.
                                </small> <br />
                            </div>
                            <img src={joketext} alt="" className="joke" />

                        </div>
                        <span class="ps-sp-top">3<sup>rd</sup></span>
                    </li>
                </ol>
            </div>
        </section>

    );
};

export default RoadmapComponent;
